import React from 'react'

import * as StateImages from '@images/states'
import * as LayoutImages from '@images/layout'
import * as ExampleImages from '@images/examples'
import * as DrawerImages from '@images/components/drawer'
import * as ModalImages from '@images/components/modal'
import * as TableImages from '@images/components/table'
import * as ToastImages from '@images/components/toast'
import { Tab, Table } from '@sainsburys-tech/fable'

const images = {
  // State Page Images
  'states-in-components': StateImages.StatesInComponents,
  'active-inactive-sains': StateImages.ActiveInactiveSains,
  'active-inactive-argos': StateImages.ActiveInactiveArgos,
  'active-inactive-habitat': StateImages.ActiveInactiveHabitat,
  'active-inactive-tu': StateImages.ActiveInactiveTu,
  'active-inactive-nectar': StateImages.ActiveInactiveNectar,
  'hovered-state': StateImages.HoveredState,
  'pressed-state': StateImages.PressedState,
  'disabled-state': StateImages.DisabledState,
  'error-state': StateImages.ErrorState,
  // Layout Page Images
  '16-padding': LayoutImages.Padding16Example,
  '24-padding': LayoutImages.Padding24Example,
  'aspect-ratio-layout': LayoutImages.AspectRatioExample,
  'breakpoints-example': LayoutImages.BreakpointsExample,
  'column-span': LayoutImages.ColumnSpanExample,
  'container-spanning': LayoutImages.ContainerSpanningExample,
  'design-templates': LayoutImages.DesignTemplatesExample,
  'fixed-grid-1280': LayoutImages.FixedGrid1280Example,
  'fixed-grid-1440': LayoutImages.FixedGrid1440Example,
  'fixed-grid-2560': LayoutImages.FixedGrid2560Example,
  'fluid-grid-320': LayoutImages.FluidGrid320Example,
  'fluid-grid-768': LayoutImages.FluidGrid768Example,
  'fluid-grid-1024': LayoutImages.FluidGrid1024Example,
  'grid-columns': LayoutImages.GridColumnsExample,
  'grid-gutters': LayoutImages.GridGuttersExample,
  'grid-margins': LayoutImages.GridMarginsExample,
  'grid-overview': LayoutImages.GridOverviewExample,
  'spacing-4px': LayoutImages.Spacing4pxExample,
  'spacing-16-8-16': LayoutImages.Spacing16816Example,
  'touch-targets': LayoutImages.TouchTargetsExample,
  'vertical-spacing': LayoutImages.VerticalSpacingExample,
  // Kiosk page Images
  'aspect-ratio': ExampleImages.AspectRatio,
  'kiosk-resolution': ExampleImages.KioskResolution,
  // Drawer component page images
  'drawer-overview': DrawerImages.DrawerOverview,
  'drawer-anatomy': DrawerImages.DrawerAnatomy,
  'drawer-size': DrawerImages.DrawerSize,
  'drawer-position': DrawerImages.DrawerPosition, 
  'drawer-dismiss': DrawerImages.DrawerDismiss,
  'drawer-nested-do': DrawerImages.DrawerNestedDo,
  'drawer-nested-dont': DrawerImages.DrawerNestedDont,
  'drawer-heading-do': DrawerImages.DrawerHeadingDo,
  'drawer-heading-dont': DrawerImages.DrawerHeadingDont,
  'drawer-actions-do': DrawerImages.DrawerActionsDo,
  'drawer-actions-dont': DrawerImages.DrawerActionsDont,
  // Modal component page images
  'modal-overview': ModalImages.ModalOverview,
  'modal-structure': ModalImages.ModalStructure,
  'modal-nested-do': ModalImages.ModalNestedDo,
  'modal-nested-dont': ModalImages.ModalNestedDont,
  'modal-heading-do': ModalImages.ModalHeadingDo,
  'modal-heading-dont': ModalImages.ModalHeadingDont,
  'modal-actions-do': ModalImages.ModalActionsDo,
  'modal-actions-dont': ModalImages.ModalActionsDont,
  // Table component page images
  'table-example-1': TableImages.Example1,
  'table-example-2': TableImages.Example2,
  'table-example-3': TableImages.Example3,
  'table-example-4': TableImages.Example4,
  'table-example-5': TableImages.Example5,
  'table-example-6': TableImages.Example6,
  'table-example-7': TableImages.Example7,
  'table-example-8': TableImages.Example8,
  'table-example-9': TableImages.Example9,
  'table-example-10': TableImages.Example10,
  'table-example-11': TableImages.Example11,
  'table-example-12': TableImages.Example12,
  'table-example-13': TableImages.Example13,
  'table-example-14': TableImages.Example14,
  'table-example-15': TableImages.Example15,
  'table-example-16': TableImages.Example16,
  'table-example-17': TableImages.Example17,
  'table-example-18': TableImages.Example18,
  'table-example-19': TableImages.Example19,
  'table-example-20': TableImages.Example20,
  'table-example-21': TableImages.Example21,
  'table-example-22': TableImages.Example22,
  'table-example-23': TableImages.Example23,
  'table-example-24': TableImages.Example24,
  // Toast component page images
  'toast-overview': ToastImages.ToastOverview,
  'toast-variant-system': ToastImages.ToastVariantSystem,
  'toast-variant-success': ToastImages.ToastVariantSuccess,
  'toast-variant-error': ToastImages.ToastVariantError,
  'toast-variant-warning': ToastImages.ToastVariantWarning,
  'toast-variant-info': ToastImages.ToastVariantInfo,
  'toast-dismissable': ToastImages.ToastDismissable,
  'toast-action': ToastImages.ToastAction,
  'toast-icon': ToastImages.ToastIcon,
  'toast-wrapping': ToastImages.ToastWrapping,
  'toast-placement': ToastImages.ToastPlacement,
  'toast-size': ToastImages.ToastSize,
  'toast-semantic-do': ToastImages.ToastSemanticDo,
  'toast-semantic-dont': ToastImages.ToastSemanticDont,
  'toast-position-do': ToastImages.ToastPositionDo,
  'toast-position-dont': ToastImages.ToastPositionDont,
  'toast-content-do': ToastImages.ToastContentDo,
  'toast-content-dont': ToastImages.ToastContentDont,
}


const ImageWithCaption = ({ imageName, alt, caption, type, image }) => {
  return (
    <figure>
      {type && type.toLowerCase() !== 'svg' ? (
        <img src={image || images[imageName]} alt={alt} />
      ) : (
        image || images[imageName]
      )}
      {caption && (
        <figcaption style={{ fontSize: 'small' }}>{caption}</figcaption>
      )}
    </figure>
  )
}

export default ImageWithCaption
